import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { IncomingMessage } from 'http';
import { Meditation } from 'models/meditation';
import { MusicTrack } from 'models/music-track';
import { State as RootState } from 'models/redux-state';
import { Track } from 'models/track';
import { Actions as audioTracksActions } from 'domains/audio-tracks';
import { Actions as meditationsActions } from 'domains/meditations';
import { Actions as userActions, Selectors as userSelectors } from 'domains/user';
import { Actions as musicActions } from 'domains/music';
import Axios from 'utils/axios';
import { getAxiosAuthClientOptions } from 'utils/get-auth-options';
import { NAME } from './constants';
import * as Selectors from './selectors';

export const fetchFavorites = createAsyncThunk<void, IncomingMessage | undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-favorites`,
  async (req, thunkAPI) => {
    const user = userSelectors.getUser(thunkAPI.getState());
    // if there is no user, we are not logged in and cannot have favorites
    if (!user) {
      return;
    }

    const favoriteMeditations = userSelectors.getFavoriteMeditations(thunkAPI.getState());
    const favoriteMusicTracks = userSelectors.getFavoriteMusicTracks(thunkAPI.getState());
    if (!favoriteMeditations || !favoriteMusicTracks) {
      const options = getAxiosAuthClientOptions(req);
      const { data } = await Axios.get<{ favoriteMeditations: string[]; favoriteMusicTracks: string[] }>(
        '/api/user/favorites',
        options,
      );
      thunkAPI.dispatch(userActions.setFavoriteMeditations(user.uid, data.favoriteMeditations));
      thunkAPI.dispatch(userActions.setFavoriteMusicTracks(user.uid, data.favoriteMusicTracks));
    }
  },
);

export const fetchFavoriteMeditations = createAsyncThunk<
  void,
  IncomingMessage | undefined,
  { state: CombinedState<RootState> }
>(`${NAME}/fetch-favorite-meditations`, async (req, thunkAPI) => {
  const user = userSelectors.getUser(thunkAPI.getState());
  // if there is no user, we are not logged in and cannot have favorites
  if (!user) {
    return;
  }
  let favorites = userSelectors.getFavoriteMeditations(thunkAPI.getState());

  if (!favorites?.length) {
    const options = getAxiosAuthClientOptions(req);
    const { data } = await Axios.get<{ favorites: string[]; meditations: Meditation[]; tracks: Track[] }>(
      '/api/user/favorites/meditations',
      options,
    );

    if (!data.favorites) {
      return;
    }

    favorites = data.favorites;

    thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
    thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    thunkAPI.dispatch(userActions.setFavoriteMeditations(user.uid, data.favorites));
  }

  const favoriteMeditations = Selectors.getFavoriteMeditations(thunkAPI.getState());

  if (favoriteMeditations.length !== favorites.length) {
    const meditationsToFetch = favorites.filter(
      (meditationUid) => !favoriteMeditations.find(({ uid }) => meditationUid === uid),
    );
    const { data } = await Axios.get<{ meditations: Meditation[]; tracks: Track[] }>('/api/meditations', {
      params: { uids: meditationsToFetch },
    });
    thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
    thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
  }
});

export const fetchFavoriteMusicTracks = createAsyncThunk<
  void,
  IncomingMessage | undefined,
  { state: CombinedState<RootState> }
>(`${NAME}/fetch-favorite-meditations`, async (req, thunkAPI) => {
  const user = userSelectors.getUser(thunkAPI.getState());
  // if there is no user, we are not logged in and cannot have favorites
  if (!user) {
    return;
  }

  let favorites = userSelectors.getFavoriteMusicTracks(thunkAPI.getState());

  if (!favorites) {
    const options = getAxiosAuthClientOptions(req);
    const { data } = await Axios.get<{ favorites: string[]; musicTracks: MusicTrack[]; tracks: Track[] }>(
      '/api/user/favorites/music',
      options,
    );

    if (!data.favorites) {
      return;
    }

    favorites = data.favorites;

    thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
    thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    thunkAPI.dispatch(userActions.setFavoriteMusicTracks(user.uid, data.favorites));
  }

  const favoriteMusicTracks = Selectors.getFavoriteMusicTracks(thunkAPI.getState());

  if (favoriteMusicTracks.length !== favorites.length) {
    const musicTracksToFetch = favorites.filter(
      (musicTrackUid) => !favoriteMusicTracks.find(({ uid }) => musicTrackUid === uid),
    );
    const { data } = await Axios.get<{ musicTracks: MusicTrack[]; tracks: Track[] }>('/api/music', {
      params: { uids: musicTracksToFetch },
    });
    thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
    thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
  }
});

export const updateFavoriteMeditation = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/update-favorite-meditation-track`,
  async (uid, thunkAPI) => {
    await thunkAPI.dispatch(fetchFavorites(undefined));
    const favorites = userSelectors.getFavoriteMeditations(thunkAPI.getState());
    const user = userSelectors.getUser(thunkAPI.getState());
    let newFavorites;

    try {
      // if favorite, remove favorite
      if (favorites.includes(uid)) {
        // first update the favorites itself to prevent excessive calls.
        thunkAPI.dispatch(
          userActions.setFavoriteMeditations(
            user.uid,
            favorites.filter((favoriteUid) => favoriteUid !== uid),
          ),
        );

        const { data } = await Axios.post<string[]>('/api/user/favorites/meditations/remove', { uid });
        newFavorites = data;

        // if not, add favorite
      } else {
        // first update the favorites itself to prevent wait user time and excessive calls.
        thunkAPI.dispatch(userActions.setFavoriteMeditations(user.uid, [uid, ...favorites]));
        const { data } = await Axios.post<string[]>('/api/user/favorites/meditations/add', { uid });
        newFavorites = data;
      }
    } catch (error) {
      // if the call failed, we reset the favorites to what it was.
      newFavorites = favorites;
    }

    thunkAPI.dispatch(userActions.setFavoriteMeditations(user.uid, newFavorites));
  },
);

export const updateFavoriteMusicTrack = createAsyncThunk<void, string, { state: CombinedState<RootState> }>(
  `${NAME}/update-favorite-music-track`,
  async (uid, thunkAPI) => {
    await thunkAPI.dispatch(fetchFavorites(undefined));
    const favorites = userSelectors.getFavoriteMusicTracks(thunkAPI.getState());
    const user = userSelectors.getUser(thunkAPI.getState());
    let newFavorites;

    try {
      // if favorite, remove favorite
      if (favorites.includes(uid)) {
        // first update the favorites itself to prevent excessive calls.
        thunkAPI.dispatch(
          userActions.setFavoriteMusicTracks(
            user.uid,
            favorites.filter((favoriteUid) => favoriteUid !== uid),
          ),
        );
        const { data } = await Axios.post<string[]>('/api/user/favorites/music/remove', { uid });
        newFavorites = data;

        // if not, add favorite
      } else {
        // first update the favorites itself to prevent wait user time and excessive calls.
        thunkAPI.dispatch(userActions.setFavoriteMusicTracks(user.uid, [uid, ...favorites]));
        const { data } = await Axios.post<string[]>('/api/user/favorites/music/add', { uid });
        newFavorites = data;
      }
    } catch (error) {
      // if the call failed, we reset the favorites to what it was.
      newFavorites = favorites;
    }

    thunkAPI.dispatch(userActions.setFavoriteMusicTracks(user.uid, newFavorites));
  },
);
