import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { ParsedUrlQuery } from 'querystring';
import { MusicTrack } from 'models/music-track';
import { Track } from 'models/track';
import { State as RootState } from 'models/redux-state';
import { Actions as audioTracksActions } from 'domains/audio-tracks';
import { Actions as musicActions } from 'domains/music';
import { MUSIC_TRACK_QUERY_PARAM } from 'config/constants';
import Axios from 'utils/axios';
import * as Selectors from '../selectors';
import { NAME } from '../constants';

export const fetchSharedTrack = createAsyncThunk<
  void,
  { query: ParsedUrlQuery; locale: string },
  { state: CombinedState<RootState> }
>(`${NAME}/fetch-shared-track`, async ({ query, locale }, thunkAPI) => {
  const slug = query?.[MUSIC_TRACK_QUERY_PARAM];
  const musicTrack = Selectors.getLocalizedMusicTrackBySlug(thunkAPI.getState(), { slug: slug as string });

  if (!slug || musicTrack) {
    return;
  }

  const { data } = await Axios.get<{ musicTrack: MusicTrack; tracks: Track[] }>(`/api/music/${slug}`, {
    params: { locale },
  });

  thunkAPI.dispatch(musicActions.updateMusicTracks([data.musicTrack]));
  thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
});
