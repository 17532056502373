import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Meditation } from 'models/meditation';
import { Moment } from 'models/moment';
import { State as RootState } from 'models/redux-state';
import { Track } from 'models/track';
import { Actions as meditationsActions } from 'domains/meditations';
import { Actions as audioTracksActions } from 'domains/audio-tracks';
import { Actions as sectionsActions, Selectors as sectionsSelectors } from 'domains/sections';
import { Selectors as userSelectors } from 'domains/user';
import Axios from 'utils/axios';
import { NAME } from '../constants';
import * as Selectors from '../selectors';

export const fetchMoments = createAsyncThunk<void, void, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-moments`,
  async (arg, thunkAPI) => {
    const locale = userSelectors.getLocale(thunkAPI.getState());
    const moments = sectionsSelectors.getLocalizedMoments(thunkAPI.getState(), { locale });

    if (!moments) {
      const { data } = await Axios.get<{ moments: Moment[]; meditations: Meditation[]; tracks: Track[] }>(
        '/api/meditations/moments',
        { params: { locale } },
      );
      thunkAPI.dispatch(meditationsActions.updateMoments(data.moments));
      thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
      thunkAPI.dispatch(
        sectionsActions.updateMoments(
          locale,
          data.moments.map(({ uid }) => uid),
        ),
      );
    }
  },
);

export const fetchMomentBySlug = createAsyncThunk<boolean, string, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-moment-by-slug`,
  async (slug, thunkAPI) => {
    let moment = Selectors.getLocalizedMomentBySlug(thunkAPI.getState(), { slug });

    if (!moment) {
      const locale = userSelectors.getLocale(thunkAPI.getState());
      const { data } = await Axios.get<{ moment: Moment; meditations: Meditation[]; tracks: Track[] }>(
        `/api/meditations/moments/${slug}`,
        {
          params: { locale },
        },
      );

      if (!data.moment) {
        return false;
      }

      moment = data.moment;

      thunkAPI.dispatch(meditationsActions.updateMoments([data.moment]));
      thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }

    const momentMeditations = Selectors.getLocalizedMomentMeditationsBySlug(thunkAPI.getState(), { slug });

    if (momentMeditations.length !== moment.tracks.length) {
      const meditationsToFetch = moment.tracks.filter(
        (meditationUid) => !momentMeditations.find(({ uid }) => uid === meditationUid),
      );

      const { data } = await Axios.get<{ meditations: Meditation[]; tracks: Track[] }>('/api/meditations', {
        params: { uids: meditationsToFetch },
      });

      thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }

    return true;
  },
);
