import { LOCALES } from 'config/constants';
import { NextPageContext } from 'next';
import { Actions as errorsActions, ErrorCodes } from 'domains/errors';
import { Thunks as appThunks } from 'domains/app';
import { Thunks as userThunks } from 'domains/user';
import { createStore, ThunkStore } from 'domains/redux';
import { Thunks as logicAppThunks } from 'logic-domains/app';
import { Thunks as favoriteThunks } from 'logic-domains/favorites';
import { MomentThunks } from 'logic-domains/meditations';
import { CategoriesThunks, TrackThunks } from 'logic-domains/music';

type Props = Record<string, unknown>;

type StoreContext = NextPageContext & { store: ThunkStore };

type GetInitialProps = (context: StoreContext) => Promise<Props>;

const getInitialPropsWithStore =
  (getInitialProps: GetInitialProps) =>
  async (context: NextPageContext): Promise<Props> => {
    // create the redux store
    const store = createStore();
    // set host
    store.dispatch(appThunks.updateHost({ req: context?.req, asPath: context.asPath, locale: context.locale }));
    // reset error
    store.dispatch(errorsActions.setErrorCode(ErrorCodes.NoError));
    // set the locale
    await store.dispatch(userThunks.setInitialLocale(context?.locale));
    // fetch user
    await store.dispatch(userThunks.fetchUser({ req: context?.req, res: context?.res }));
    // check if the user needs to be redirected before fetch all data
    await store.dispatch(
      userThunks.redirectToUserLocale({
        req: context?.req,
        res: context?.res,
        locale: context?.locale as LOCALES,
        currentUrl: context?.pathname,
      }),
    );
    // fetch timezone
    await store.dispatch(userThunks.setTimezoneDataByRequest(context?.req));
    // get app info - has to wait on the user and timezone thunk, since it depends on the timezone.
    await store.dispatch(logicAppThunks.fetchAppInfo());
    // get favorites
    await store.dispatch(favoriteThunks.fetchFavorites(context?.req));
    // get moments
    await store.dispatch(MomentThunks.fetchMoments());
    // get categories
    await store.dispatch(CategoriesThunks.fetchMusicCategories());
    // fetch shared track
    await store.dispatch(TrackThunks.fetchSharedTrack({ locale: context?.locale, query: context?.query }));
    // increase visits only when on server.
    if (context.req) {
      await store.dispatch(appThunks.increaseVisits({ req: context.req, res: context.res }));
    }

    // await the results of the page props.
    const results = await getInitialProps({ ...context, store });

    // return the results gathered by the initialProps together with the state of the store.
    return {
      ...results,
      initialState: store.getState(),
    };
  };

export default getInitialPropsWithStore;
