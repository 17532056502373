import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Actions as musicActions } from 'domains/music';
import { Actions as sectionsActions, Selectors as sectionsSelectors } from 'domains/sections';
import { Selectors as userSelectors } from 'domains/user';
import { Actions as audioTracksActions } from 'domains/audio-tracks';
import { MusicCategory } from 'models/music-category';
import { MusicTrack } from 'models/music-track';
import { State as RootState } from 'models/redux-state';
import { Track } from 'models/track';
import Axios from 'utils/axios';
import { NAME } from '../constants';
import * as Selectors from '../selectors';

export const fetchMusicCategories = createAsyncThunk<void, void, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-music-categories`,
  async (arg, thunkAPI) => {
    const locale = userSelectors.getLocale(thunkAPI.getState());
    const musicCategories = sectionsSelectors.getLocalizedMusicCategories(thunkAPI.getState(), { locale });

    if (!musicCategories) {
      const { data } = await Axios.get<{ categories: MusicCategory[]; musicTracks: MusicTrack[]; tracks: Track[] }>(
        '/api/music/categories',
        {
          params: { locale },
        },
      );

      thunkAPI.dispatch(musicActions.updateCategories(data.categories));
      thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
      thunkAPI.dispatch(
        sectionsActions.updateMusicCategories(
          locale,
          data.categories.map(({ uid }) => uid),
        ),
      );
    }
  },
);

export const fetchCategoryBySlug = createAsyncThunk<boolean, string, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-music-category-by-slug`,
  async (slug, thunkAPI) => {
    let category = Selectors.getLocalizedMusicCategoryBySlug(thunkAPI.getState(), { slug });

    if (!category) {
      const locale = userSelectors.getLocale(thunkAPI.getState());
      const { data } = await Axios.get<{ category: MusicCategory; musicTracks: MusicTrack[]; tracks: Track[] }>(
        `/api/music/categories/${slug}`,
        {
          params: { locale },
        },
      );

      if (!data.category) {
        return false;
      }

      category = data.category;

      thunkAPI.dispatch(musicActions.updateCategories([data.category]));
      thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }

    const categoryMusicTracks = Selectors.getLocalizedMusicCategoryMusicTracksBySlug(thunkAPI.getState(), { slug });

    if (categoryMusicTracks.length !== category.tracks.length) {
      const musicTracksToFetch = category.tracks.filter(
        (meditationUid) => !categoryMusicTracks.find(({ uid }) => uid === meditationUid),
      );

      const { data } = await Axios.get<{ musicTracks: MusicTrack[]; tracks: Track[] }>('/api/music', {
        params: { uids: musicTracksToFetch },
      });

      thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }

    return true;
  },
);
