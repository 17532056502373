import { createSelector } from 'reselect';
import { Selectors as userSelectors } from 'domains/user';
import { Selectors as meditationSelectors } from 'domains/meditations';
import { Selectors as musicSelectors } from 'domains/music';

export const getFavoriteMeditations = createSelector(
  meditationSelectors.getMoments,
  meditationSelectors.getMeditationsMap,
  userSelectors.getFavoriteMeditations,
  (moments, meditationMap, favoriteMeditations) =>
    favoriteMeditations
      ?.map((uid) => {
        const meditation = meditationMap[uid];
        if (!meditation) {
          return undefined;
        }

        const categories = moments.filter(({ tracks }) => tracks.includes(meditation.uid)).map(({ uid }) => uid);
        return { ...meditation, categories };
      })
      .filter(Boolean) ?? [],
);

export const getLocalizedFavoriteMeditations = createSelector(
  userSelectors.getLocale,
  getFavoriteMeditations,
  (locale, favoriteMeditations) => favoriteMeditations.filter((meditation) => meditation.locale === locale),
);

export const getFavoriteMusicTracks = createSelector(
  musicSelectors.getCategories,
  musicSelectors.getMusicTracksMap,
  userSelectors.getFavoriteMusicTracks,
  (musicCategories, musicTracksMap, favoriteMusicTracks) =>
    favoriteMusicTracks
      ?.map((uid) => {
        const musicTrack = musicTracksMap[uid];
        if (!musicTrack) {
          return undefined;
        }

        const categories = musicCategories
          .filter(({ tracks }) => tracks.includes(musicTrack.uid))
          .map(({ uid }) => uid);
        return { ...musicTrack, categories };
      })
      .filter(Boolean) ?? [],
);

export const getLocalizedFavoriteMusicTracks = createSelector(
  userSelectors.getLocale,
  getFavoriteMusicTracks,
  (locale, favoriteMusicTracks) => favoriteMusicTracks.filter((musicTrack) => musicTrack.locale === locale),
);
